import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
    socialMediaButton: {
        color: '#FFF',
        padding: '8px',
        '&:hover': {
            color: '#FFF'
        }
    },
}));